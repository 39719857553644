module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/atlassian/pipelines/agent/build/src/locales","languages":["es","en","de","fr","it","nl","pl"],"defaultLanguage":"","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":":"},"pages":[{"matchPath":"/","getLanguageFromPath":false},{"matchPath":"/booking","getLanguageFromPath":false},{"matchPath":"/404","getLanguageFromPath":false},{"matchPath":"/:lang/checkout/","getLanguageFromPath":true},{"matchPath":"/:lang/checkout/result/","getLanguageFromPath":true},{"matchPath":"/:lang/checkout/payment/","getLanguageFromPath":true},{"matchPath":"/:lang/(.*)","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.tamaran.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
